////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//
//  Copyright (C) Quantron Systems LLC.
//  All Rights Reserved.
//
//  This file is part of the private project.
//  For conditions of distribution and use,
//  please contact sales@quantron-systems.com
//
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
import _ from 'lodash';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import Cookies from 'js-cookie';
import { notify } from '../helpers';
import config from './config';
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
var authKeyName = _.get(config, 'authKeyName') || '';
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
var tagTypes = ['Stations', 'Station', 'SaveStationBackground'];
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export var stationAPI = createApi({
    reducerPath: 'stationAPI',
    baseQuery: fetchBaseQuery({ baseUrl: config.apiUrl }),
    tagTypes: tagTypes,
    endpoints: function (build) { return ({
        getStations: build.query({
            query: function () {
                var _a;
                return ({
                    method: 'GET',
                    url: '/api/v2/admin/station',
                    headers: (_a = {}, _a[authKeyName] = Cookies.get('auth-key'), _a)
                });
            },
            providesTags: function () { return ['Stations']; }
        }),
        getStation: build.query({
            query: function (id) {
                var _a;
                return ({
                    method: 'GET',
                    url: "/api/v2/admin/station/".concat(id),
                    headers: (_a = {}, _a[authKeyName] = Cookies.get('auth-key'), _a)
                });
            },
            providesTags: function () { return ['Station']; }
        }),
        saveStation: build.mutation({
            query: function (body) {
                var _a;
                return ({
                    url: '/api/v2/admin/station',
                    method: 'POST',
                    body: body,
                    headers: (_a = {}, _a[authKeyName] = Cookies.get('auth-key'), _a),
                    responseHandler: function (response) {
                        notify(response);
                        return response.json();
                    }
                });
            },
            invalidatesTags: ['Station', 'Stations']
        }),
        deleteStation: build.mutation({
            query: function (post) {
                var _a;
                return ({
                    url: "/api/v2/admin/station/".concat(post.stationId),
                    method: 'DELETE',
                    headers: (_a = {}, _a[authKeyName] = Cookies.get('auth-key'), _a),
                    responseHandler: function (response) {
                        notify(response, 'Станция удалена');
                        return response.json();
                    }
                });
            },
            invalidatesTags: ['Stations']
        }),
        saveBackground: build.mutation({
            query: function (_a) {
                var _b;
                var stationId = _a.stationId, body = _a.body;
                return ({
                    url: "/api/v2/admin/station/".concat(stationId, "/background"),
                    method: 'POST',
                    body: body,
                    headers: (_b = {}, _b[authKeyName] = Cookies.get('auth-key'), _b),
                    responseHandler: function (response) {
                        notify(response);
                        return response.json();
                    }
                });
            },
            invalidatesTags: ['Station', 'SaveStationBackground']
        }),
        deleteBackground: build.mutation({
            query: function (post) {
                var _a;
                return ({
                    url: "/api/v2/admin/station/".concat(post.stationId, "/background/").concat(post.selectelFileId),
                    method: 'DELETE',
                    headers: (_a = {}, _a[authKeyName] = Cookies.get('auth-key'), _a)
                });
            },
            invalidatesTags: ['Station']
        }),
        getPreview: build.query({
            query: function (body) {
                var _a;
                return ({
                    url: "/api/v2/admin/station/".concat(body.id, "/background"),
                    method: 'GET',
                    params: {
                        category: body.category,
                        side: body.side,
                        preview: body.preview
                    },
                    responseHandler: function (response) { return response.blob(); },
                    headers: (_a = {}, _a[authKeyName] = Cookies.get('auth-key'), _a)
                });
            },
            providesTags: function () { return ['SaveStationBackground']; }
        }),
        applyBackground: build.mutation({
            query: function (body) {
                var _a;
                return ({
                    url: '/api/v2/admin/station/applyBackgroundPreview',
                    method: 'POST',
                    body: body,
                    responseHandler: function (response) { return response.blob(); },
                    headers: (_a = {}, _a[authKeyName] = Cookies.get('auth-key'), _a)
                });
            },
            invalidatesTags: ['Station']
        })
    }); }
});
