////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//
//  Copyright (C) Quantron Systems LLC.
//  All Rights Reserved.
//
//  This file is part of the private project.
//  For conditions of distribution and use,
//  please contact sales@quantron-systems.com
//
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
import _ from 'lodash';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import config from './config';
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
var authKeyName = _.get(config, 'authKeyName') || '';
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export var userAPI = createApi({
    reducerPath: 'UserAPI',
    baseQuery: fetchBaseQuery({ baseUrl: config.apiUrl }),
    tagTypes: ['User'],
    endpoints: function (build) { return ({
        auth: build.query({
            query: function (key) {
                var _a;
                return ({
                    method: 'GET',
                    url: '/api/v2/admin/authKey/whoami',
                    headers: (_a = {}, _a[authKeyName] = key, _a)
                });
            }
        })
    }); }
});
