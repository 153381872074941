var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//
//  Copyright (C) Quantron Systems LLC.
//  All Rights Reserved.
//
//  This file is part of the private project.
//  For conditions of distribution and use,
//  please contact sales@quantron-systems.com
//
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Form, Button, ButtonToolbar, Alert } from '../../components/common/bootstrap';
import Spinner from '../../components/common/Spinner';
import { userAPI } from '../../api/userAPI';
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function Login() {
    var _this = this;
    var _a = __read(useState(true), 2), skip = _a[0], setSkip = _a[1];
    var _b = __read(useState(''), 2), inputValue = _b[0], setInputValue = _b[1];
    var _c = userAPI.useAuthQuery(inputValue, { skip: skip }), data = _c.data, isSuccess = _c.isSuccess, isError = _c.isError, isLoading = _c.isLoading;
    var navigate = useNavigate();
    var onInput = function (e) {
        e.preventDefault();
        var value = e.target.value;
        setInputValue(value);
    };
    var onSubmit = function (event) { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            event.preventDefault();
            setSkip(!skip);
            return [2 /*return*/];
        });
    }); };
    useEffect(function () {
        if (data && isSuccess) {
            Cookies.set('auth-key', inputValue);
            Cookies.set('username', data.name);
            Cookies.set('userId', data.id);
            Cookies.set('role', data.role);
            navigate('/');
        }
    }, [data]);
    return (_jsx("div", __assign({ className: 'bg-gradient-primary min-vh-100' }, { children: _jsx(Container, __assign({ fluid: true }, { children: _jsx(Row, __assign({ className: 'justify-content-center align-items-center min-vh-100' }, { children: _jsx(Col, __assign({ sm: 4 }, { children: _jsx("div", __assign({ className: 'card o-hidden border-0 shadow-lg my-5' }, { children: _jsxs(Form, __assign({ onSubmit: onSubmit, className: 'p-5' }, { children: [_jsxs(Form.Group, __assign({ className: 'mb-3', controlId: 'key' }, { children: [_jsx(Form.Label, { children: "\u041A\u043B\u044E\u0447 \u0434\u043E\u0441\u0442\u0443\u043F\u0430" }, void 0), _jsx(Form.Control, { onChange: onInput, value: inputValue, type: 'password', placeholder: '\u0412\u0432\u0435\u0434\u0438\u0442\u0435 \u043A\u043B\u044E\u0447 \u0434\u043E\u0441\u0442\u0443\u043F\u0430', required: true }, void 0)] }), void 0), isError && _jsx(Alert, __assign({ variant: 'danger' }, { children: "\u041F\u0440\u043E\u0438\u0437\u043E\u0448\u043B\u0430 \u043E\u0448\u0438\u0431\u043A\u0430. \u041F\u0440\u043E\u0432\u0435\u0440\u0442\u0435 \u043F\u0440\u0430\u0432\u0438\u043B\u044C\u043D\u043E\u0441\u0442\u044C \u043A\u043B\u044E\u0447\u0430" }), void 0), _jsx(ButtonToolbar, __assign({ className: 'justify-content-end' }, { children: _jsx(Button, __assign({ variant: 'primary', type: 'submit', className: 'justify-content-end' }, { children: isLoading ? _jsx(Spinner, { sm: true }, void 0) : 'Ок' }), void 0) }), void 0)] }), void 0) }), void 0) }), void 0) }), void 0) }), void 0) }), void 0));
}
export default Login;
