import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useAuth } from '../../hooks';
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function CheckAdmin(_a) {
    var children = _a.children;
    var isAdmin = useAuth().isAdmin;
    if (!isAdmin)
        return _jsx("div", {}, void 0);
    return (_jsx(_Fragment, { children: children }, void 0));
}
export default CheckAdmin;
