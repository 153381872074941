////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//
//  Copyright (C) Quantron Systems LLC.
//  All Rights Reserved.
//
//  This file is part of the private project.
//  For conditions of distribution and use,
//  please contact sales@quantron-systems.com
//
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
import * as Yup from 'yup';
import _ from 'lodash';
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
var SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png'];
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export var saveStationSchema = Yup.object().shape({
    name: Yup.string().required('Название станции обязательно'),
    registryNumber: Yup.string().required('Регистрационный номер обязателен'),
    id: Yup.string().required('Идентификатор обязателен'),
    ownerAuthKeyId: Yup.string().test('', 'Выберите владельца', function (value) { return value !== '- - -'; }).required()
});
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export var saveBackgroundSchema = Yup.object().shape({
    file: Yup.mixed().nullable().test('', 'Файл не выбран или формат не поддерживается', function (value) { var _a; return value && _.includes(SUPPORTED_FORMATS, (_a = value[0]) === null || _a === void 0 ? void 0 : _a.type); }).required(),
    side: Yup.string().test('', 'Выберите вид', function (value) { return value !== '- - -'; }).required('Вид обязателен'),
    category: Yup.string().test('', 'Выберите категорию', function (value) { return value !== '- - -'; }).required('Категория обязательна')
});
