var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//
//  Copyright (C) Quantron Systems LLC.
//  All Rights Reserved.
//
//  This file is part of the private project.
//  For conditions of distribution and use,
//  please contact sales@quantron-systems.com
//
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
import { useState } from 'react';
import ConfirmationModal from '../components/common/ConfirmationModal';
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
var withConfirmation = function (Component) {
    return function (props) {
        var _a = __read(useState(false), 2), show = _a[0], setShow = _a[1];
        var _b = __read(useState({ title: '' }), 2), confirmation = _b[0], setConfirmation = _b[1];
        var doConfirmation = function (title, onConfirm) {
            setShow(true);
            setConfirmation({ title: title, onConfirm: onConfirm });
        };
        var onClose = function (confirmed) {
            setShow(false);
            if (confirmed && confirmation.onConfirm) {
                confirmation.onConfirm();
            }
        };
        return (_jsxs(_Fragment, { children: [_jsx(Component, __assign({}, props, { doConfirmation: doConfirmation }), void 0), show && _jsx(ConfirmationModal, { confirmation: confirmation.title, onClose: onClose }, void 0)] }, void 0));
    };
};
export default withConfirmation;
