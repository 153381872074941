var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, Modal } from './bootstrap';
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function ConfirmationModal(_a) {
    var onClose = _a.onClose, confirmation = _a.confirmation;
    var handleNo = function () {
        onClose(false);
    };
    var handleYes = function () {
        onClose(true);
    };
    return (_jsx(_Fragment, { children: _jsxs(Modal, __assign({ show: true, onHide: handleNo }, { children: [_jsx(Modal.Header, __assign({ closeButton: true }, { children: _jsx(Modal.Title, { children: "\u0412\u043D\u0438\u043C\u0430\u043D\u0438\u0435" }, void 0) }), void 0), _jsx(Modal.Body, { children: confirmation }, void 0), _jsxs(Modal.Footer, { children: [_jsx(Button, __assign({ variant: 'secondary', onClick: handleNo }, { children: "\u041D\u0435\u0442" }), void 0), _jsx(Button, __assign({ variant: 'success', onClick: handleYes }, { children: "\u0414\u0430" }), void 0)] }, void 0)] }), void 0) }, void 0));
}
export default ConfirmationModal;
