var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//
//  Copyright (C) Quantron Systems LLC.
//  All Rights Reserved.
//
//  This file is part of the private project.
//  For conditions of distribution and use,
//  please contact sales@quantron-systems.com
//
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
import { useEffect } from 'react';
import moment from 'moment';
import _ from 'lodash';
import { Row, Col, DropdownButton, Dropdown } from '../common/bootstrap';
import { setFromDate, setToDate } from '../../redux/slices';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { useUrl } from '../../hooks';
import { DateInput } from './inputs';
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
var StartEndFilter = function () {
    var dispatch = useAppDispatch();
    var fromDate = useAppSelector(function (state) { return state.filters.fromDate; });
    var toDate = useAppSelector(function (state) { return state.filters.toDate; });
    var _a = useUrl(), urlParams = _a.urlParams, setUrlParams = _a.setUrlParams;
    useEffect(function () {
        setUrlParams({
            fromDate: fromDate,
            toDate: toDate
        });
    }, [fromDate, toDate]);
    useEffect(function () {
        var from = urlParams === null || urlParams === void 0 ? void 0 : urlParams.fromDate;
        var to = urlParams === null || urlParams === void 0 ? void 0 : urlParams.toDate;
        if (from && from != fromDate) {
            dispatch(setFromDate(from));
        }
        if (to && to != toDate) {
            dispatch(setToDate(to));
        }
    }, []);
    var periods = [
        {
            title: 'За последние 30 дней',
            onClick: function () {
                var start = moment().subtract({ days: 30 }).set({ hour: 0, minute: 0, second: 0 });
                var end = moment().add({ days: 1 }).set({ hour: 0, minute: 0, second: 0 });
                dispatch(setFromDate(start.format('DD.MM.YYYY')));
                dispatch(setToDate(end.format('DD.MM.YYYY')));
            }
        },
        {
            title: 'За сегодня',
            onClick: function () {
                var start = moment().set({ hour: 0, minute: 0, second: 0 });
                var end = moment().add({ days: 1 }).set({ hour: 0, minute: 0, second: 0 });
                dispatch(setFromDate(start.format('DD.MM.YYYY')));
                dispatch(setToDate(end.format('DD.MM.YYYY')));
            }
        },
        {
            title: 'За вчера',
            onClick: function () {
                var start = moment().subtract({ days: 1 }).set({ hour: 0, minute: 0, second: 0 });
                var end = moment().set({ hour: 0, minute: 0, second: 0 });
                dispatch(setFromDate(start.format('DD.MM.YYYY')));
                dispatch(setToDate(end.format('DD.MM.YYYY')));
            }
        },
        {
            title: 'За эту неделю',
            onClick: function () {
                var start = moment().startOf('week').set({ hour: 0, minute: 0, second: 0 });
                var end = moment().endOf('week').add({ days: 1 }).set({ hour: 0, minute: 0, second: 0 });
                dispatch(setFromDate(start.format('DD.MM.YYYY')));
                dispatch(setToDate(end.format('DD.MM.YYYY')));
            }
        },
        {
            title: 'За этот месяц',
            onClick: function () {
                var start = moment().startOf('month').set({ hour: 0, minute: 0, second: 0 });
                var end = moment().endOf('month').add({ days: 1 }).set({ hour: 0, minute: 0, second: 0 });
                dispatch(setFromDate(start.format('DD.MM.YYYY')));
                dispatch(setToDate(end.format('DD.MM.YYYY')));
            }
        },
        {
            title: 'За прошлый месяц',
            onClick: function () {
                var start = moment().subtract(1, 'months').startOf('month').set({ hour: 0, minute: 0, second: 0 });
                var end = moment().subtract(1, 'months').endOf('month').add({ days: 1 })
                    .set({ hour: 0, minute: 0, second: 0 });
                dispatch(setFromDate(start.format('DD.MM.YYYY')));
                dispatch(setToDate(end.format('DD.MM.YYYY')));
            }
        }
    ];
    return (_jsx("div", __assign({ className: 'border p-3' }, { children: _jsxs(Row, { children: [_jsxs(Col, __assign({ md: 10 }, { children: [_jsxs(Row, __assign({ className: 'mb-2 align-items-center' }, { children: [_jsx(Col, __assign({ md: 3 }, { children: _jsx("span", __assign({ className: 'font-weight-bold' }, { children: "\u041E\u0442:" }), void 0) }), void 0), _jsx(Col, __assign({ md: 9 }, { children: _jsx(DateInput, { name: 'fromDate', action: setFromDate, defaultValue: fromDate }, void 0) }), void 0)] }), void 0), _jsxs(Row, __assign({ className: 'align-items-center' }, { children: [_jsx(Col, __assign({ md: 3 }, { children: _jsx("span", __assign({ className: 'font-weight-bold' }, { children: "\u0414\u043E:" }), void 0) }), void 0), _jsx(Col, __assign({ md: 9 }, { children: _jsx(DateInput, { name: 'toDate', action: setToDate, defaultValue: toDate }, void 0) }), void 0)] }), void 0)] }), void 0), _jsx(Col, __assign({ md: 2 }, { children: _jsx("div", __assign({ className: 'd-flex align-items-center h-100' }, { children: _jsx(DropdownMenu, { periods: periods }, void 0) }), void 0) }), void 0)] }, void 0) }), void 0));
};
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function DropdownMenu(_a) {
    var periods = _a.periods;
    var dispatch = useAppDispatch();
    var months = [];
    var _loop_1 = function (i) {
        months.push({
            title: moment().subtract(i, 'months').format('MMMM (YYYY)'),
            onClick: function () {
                var start = moment().subtract(i, 'months').startOf('month').set({ hour: 0, minute: 0, second: 0 });
                var end = moment().subtract(i, 'months').endOf('month').add({ days: 1 })
                    .set({ hour: 0, minute: 0, second: 0 });
                dispatch(setFromDate(start.format('DD.MM.YYYY')));
                dispatch(setToDate(end.format('DD.MM.YYYY')));
            }
        });
    };
    for (var i = 1; i <= 12; ++i) {
        _loop_1(i);
    }
    return (_jsxs(DropdownButton, __assign({ title: '', variant: 'info', size: 'sm' }, { children: [_.map(periods, function (item, index) {
                return _jsx(Dropdown.Item, __assign({ id: "".concat(index), onClick: item.onClick }, { children: item.title }), item.title);
            }), _jsx("div", { className: 'dropdown-divider' }, void 0), _.map(months, function (item, index) {
                return _jsx(Dropdown.Item, __assign({ id: "".concat(index), onClick: item.onClick }, { children: item.title }), item.title);
            })] }), void 0));
}
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export default StartEndFilter;
