var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
var Panel = function (_a) {
    var children = _a.children, header = _a.header;
    return _jsxs("div", __assign({ className: 'card shadow mb-4' }, { children: [header && _jsx("div", __assign({ className: 'card-header py-3' }, { children: _jsx("h6", __assign({ className: 'm-0 font-weight-bold text-primary' }, { children: header }), void 0) }), void 0), _jsx("div", __assign({ className: 'card-body' }, { children: children }), void 0)] }), void 0);
};
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export default Panel;
