var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Routes, Route } from 'react-router-dom';
import Home from '../pages/Home';
import Login from '../pages/Login';
import MainLayout from '../components/MainLayout';
import Stations from '../pages/Stations/Stations';
import Station from '../pages/Stations/Station';
import Partners from '../pages/Partners/Partners';
import Partner from '../pages/Partners/Partner';
import NotFoundPage from '../pages/404/NotFoundPage';
import FinancesReports from '../pages/Reports/FinancesReports';
import GuestRoute from './components/GuestRoute';
import PrivateRoute from './components/PrivateRoute';
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function AppRoutes() {
    return (_jsxs(Routes, { children: [_jsxs(Route, __assign({ path: '/', element: _jsx(PrivateRoute, { children: _jsx(MainLayout, {}, void 0) }, void 0) }, { children: [_jsx(Route, { index: true, element: _jsx(Home, {}, void 0) }, void 0), _jsx(Route, { path: 'stations', element: _jsx(Stations, {}, void 0) }, void 0), _jsx(Route, { path: 'stations/new', element: _jsx(Station, { isNew: true }, void 0) }, void 0), _jsx(Route, { path: 'stations/:id', element: _jsx(Station, {}, void 0) }, void 0), _jsx(Route, { path: 'partners', element: _jsx(Partners, {}, void 0) }, void 0), _jsx(Route, { path: 'partners/new', element: _jsx(Partner, { isNew: true }, void 0) }, void 0), _jsx(Route, { path: 'partners/:id', element: _jsx(Partner, {}, void 0) }, void 0), _jsx(Route, { path: 'financesReport', element: _jsx(FinancesReports, {}, void 0) }, void 0)] }), void 0), _jsx(Route, { path: '/login', element: _jsx(GuestRoute, { children: _jsx(Login, {}, void 0) }, void 0) }, void 0), _jsx(Route, { path: '*', element: _jsx(NotFoundPage, {}, void 0) }, void 0)] }, void 0));
}
export default AppRoutes;
