////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//
//  Copyright (C) Quantron Systems LLC.
//  All Rights Reserved.
//
//  This file is part of the private project.
//  For conditions of distribution and use,
//  please contact sales@quantron-systems.com
//
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
var _a;
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { userAPI } from '../api/userAPI';
import { stationAPI } from '../api/stationAPI';
import { partnerAPI } from '../api/partnerAPI';
import { reportsAPI } from '../api/reportsAPI';
import filterSlice from './slices/filterSlice';
import sidebarSlice from './slices/sidebarSlice';
import { CLEAR_STORE } from './actions/constants';
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
var appReducer = combineReducers((_a = {},
    _a[stationAPI.reducerPath] = stationAPI.reducer,
    _a[userAPI.reducerPath] = userAPI.reducer,
    _a[partnerAPI.reducerPath] = partnerAPI.reducer,
    _a[reportsAPI.reducerPath] = reportsAPI.reducer,
    _a.filters = filterSlice,
    _a.sidebar = sidebarSlice,
    _a));
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
var rootReducer = function (state, action) {
    if (action.type === CLEAR_STORE) {
        state = undefined;
    }
    return appReducer(state, action);
};
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export var setupStore = function () { return configureStore({
    reducer: rootReducer,
    middleware: function (getDefaultMiddleware) {
        return getDefaultMiddleware({
            serializableCheck: false
        }).concat(stationAPI.middleware, userAPI.middleware, partnerAPI.middleware, reportsAPI.middleware);
    }
}); };
