////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//
//  Copyright (C) Quantron Systems LLC.
//  All Rights Reserved.
//
//  This file is part of the private project.
//  For conditions of distribution and use,
//  please contact sales@quantron-systems.com
//
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
import { createSlice } from '@reduxjs/toolkit';
var initialState = {
    isToggled: true
};
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export var sidebarSlice = createSlice({
    name: 'sidebar',
    initialState: initialState,
    reducers: {
        setSidebarToggled: function (state, action) {
            state.isToggled = action.payload;
        }
    }
});
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export var setSidebarToggled = sidebarSlice.actions.setSidebarToggled;
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export default sidebarSlice.reducer;
