////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//
//  Copyright (C) Quantron Systems LLC.
//  All Rights Reserved.
//
//  This file is part of the private project.
//  For conditions of distribution and use,
//  please contact sales@quantron-systems.com
//
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
var _a;
import { createSlice } from '@reduxjs/toolkit';
var initialState = {
    fromDate: '',
    toDate: '',
    ownerId: '',
    requestType: '',
    search: ''
};
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export var filterSlice = createSlice({
    name: 'filters',
    initialState: initialState,
    reducers: {
        setFromDate: function (state, action) {
            state.fromDate = action.payload;
        },
        setToDate: function (state, action) {
            state.toDate = action.payload;
        },
        setOwnerId: function (state, action) {
            state.ownerId = action.payload;
        },
        setRequestType: function (state, action) {
            state.requestType = action.payload;
        },
        setSearch: function (state, action) {
            state.search = action.payload;
        }
    }
});
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export var setFromDate = (_a = filterSlice.actions, _a.setFromDate), setToDate = _a.setToDate, setOwnerId = _a.setOwnerId, setSearch = _a.setSearch, setRequestType = _a.setRequestType;
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export default filterSlice.reducer;
