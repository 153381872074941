////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//
//  Copyright (C) Quantron Systems LLC.
//  All Rights Reserved.
//
//  This file is part of the private project.
//  For conditions of distribution and use,
//  please contact sales@quantron-systems.com
//
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
import _ from 'lodash';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import Cookies from 'js-cookie';
import config from './config';
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
var authKeyName = _.get(config, 'authKeyName') || '';
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export var reportsAPI = createApi({
    reducerPath: 'reportsAPI',
    baseQuery: fetchBaseQuery({ baseUrl: config.apiUrl }),
    tagTypes: ['Reports'],
    endpoints: function (build) { return ({
        financesReport: build.mutation({
            query: function (body) {
                var _a;
                return ({
                    method: 'POST',
                    url: '/api/v2/admin/finances/report',
                    body: body,
                    headers: (_a = {}, _a[authKeyName] = Cookies.get('auth-key'), _a)
                });
            }
        })
    }); }
});
