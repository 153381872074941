import { jsx as _jsx } from "react/jsx-runtime";
import _ from 'lodash';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
ChartJS.register(CategoryScale, LinearScale, PointElement, BarElement, Title, Tooltip, Legend);
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function Chart(_a) {
    var labels = _a.labels, data = _a.data, datasetLabel = _a.datasetLabel, multiBar = _a.multiBar;
    var options = {
        responsive: true,
        interaction: {
            mode: 'index',
            intersect: false
        },
        plugins: {
            legend: {
                position: 'top'
            },
            title: {
                display: false
            }
        }
    };
    var backgroundColors = [
        '#4E73DF',
        '#FF6384',
        '#4BC0C0',
        '#006262',
        '#50C878',
        '#F4CA16',
        '#F07427',
        '#CC397B',
        '#6E7F80',
        '#620500'
    ];
    var multiBarData = _.map(data, function (item, index) { return ({
        backgroundColor: backgroundColors[index] ? backgroundColors[index] : backgroundColors[0],
        data: item.data,
        label: item.label,
        stack: 'Stack 0'
    }); });
    var chartData = {
        labels: labels,
        datasets: !multiBar ? [
            {
                label: datasetLabel,
                data: data,
                backgroundColor: '#4E73DF',
                stack: 'Stack 0'
            }
        ]
            : multiBarData
    };
    return _jsx(Bar, { options: options, data: chartData }, void 0);
}
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export default Chart;
