import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../hooks';
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
var PrivateRoute = function (_a) {
    var children = _a.children;
    var location = useLocation();
    var url = new URLSearchParams();
    url.set('redirect', location.pathname + location.search);
    var isAuth = useAuth().isAuth;
    return (_jsx(_Fragment, { children: isAuth ? children
            : _jsx(Navigate, { to: {
                    pathname: '/login',
                    search: url.toString()
                } }, void 0) }, void 0));
};
export default PrivateRoute;
