////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//
//  Copyright (C) Quantron Systems LLC.
//  All Rights Reserved.
//
//  This file is part of the private project.
//  For conditions of distribution and use,
//  please contact sales@quantron-systems.com
//
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
import _ from 'lodash';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import Cookies from 'js-cookie';
import { notify } from '../helpers';
import config from './config';
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
var authKeyName = _.get(config, 'authKeyName') || '';
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export var partnerAPI = createApi({
    reducerPath: 'PartnerAPI',
    baseQuery: fetchBaseQuery({ baseUrl: config.apiUrl }),
    tagTypes: ['Partners', 'Partner'],
    endpoints: function (build) { return ({
        getOwnerList: build.query({
            query: function () {
                var _a;
                return ({
                    method: 'GET',
                    url: '/api/v2/admin/authKey',
                    headers: (_a = {}, _a[authKeyName] = Cookies.get('auth-key'), _a)
                });
            },
            providesTags: function () { return ['Partners']; }
        }),
        getPartner: build.query({
            query: function (authKeyId) {
                var _a;
                return ({
                    method: 'GET',
                    url: "/api/v2/admin/authKey/".concat(authKeyId),
                    headers: (_a = {}, _a[authKeyName] = Cookies.get('auth-key'), _a)
                });
            },
            providesTags: function () { return ['Partner']; }
        }),
        savePartner: build.mutation({
            query: function (_a) {
                var _b;
                var body = _a.body, authKeyId = _a.authKeyId;
                return ({
                    method: 'POST',
                    url: "/api/v2/admin/authKey/".concat(authKeyId),
                    body: body,
                    headers: (_b = {}, _b[authKeyName] = Cookies.get('auth-key'), _b),
                    responseHandler: function (response) {
                        notify(response);
                        return response.json();
                    }
                });
            },
            invalidatesTags: ['Partner', 'Partners']
        })
    }); }
});
