var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { createElement as _createElement } from "react";
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//
//  Copyright (C) Quantron Systems LLC.
//  All Rights Reserved.
//
//  This file is part of the private project.
//  For conditions of distribution and use,
//  please contact sales@quantron-systems.com
//
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
import _ from 'lodash';
import { useState } from 'react';
import BTable from 'react-bootstrap/Table';
import { FaDotCircle, FaAngleRight, FaAngleDown, FaUsers, FaArrowsAltV, FaLongArrowAltUp, FaLongArrowAltDown } from 'react-icons/fa';
import { useTable, usePagination, useSortBy, useGroupBy, useExpanded } from 'react-table';
import Spinner from './common/Spinner';
/* eslint-disable no-nested-ternary */
/* eslint-disable lines-around-comment */
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function Table(_a) {
    var columns = _a.columns, data = _a.data, controlledPageCount = _a.pageCount, loading = _a.loading, _b = _a.withGroup, withGroup = _b === void 0 ? false : _b, _c = _a.withSort, withSort = _c === void 0 ? true : _c, handleSelectRow = _a.handleSelectRow, _d = _a.groupByFields, groupByFields = _d === void 0 ? [''] : _d;
    var _e = __read(useState(null), 2), selectedRow = _e[0], setSelectedRow = _e[1];
    var _f = useTable({
        columns: columns,
        data: data,
        initialState: { pageIndex: 0, groupBy: groupByFields },
        manualPagination: true,
        // hook that we'll handle our own data fetching
        // This means we'll also have to provide our own
        // pageCount.
        pageCount: controlledPageCount
    }, useGroupBy, useSortBy, useExpanded, usePagination), getTableProps = _f.getTableProps, getTableBodyProps = _f.getTableBodyProps, headerGroups = _f.headerGroups, prepareRow = _f.prepareRow, page = _f.page;
    var onSelectRow = function (row) {
        if (row && handleSelectRow) {
            setSelectedRow(row.original);
            handleSelectRow(row.original);
        }
    };
    return (_jsx("div", __assign({ className: 'table-wrapper' }, { children: _jsxs(BTable, __assign({ striped: true, bordered: true, hover: true, size: 'sm' }, getTableProps(), { children: [_jsx("thead", { children: headerGroups.map(function (headerGroup, i) { return (_createElement("tr", __assign({}, headerGroup.getHeaderGroupProps(), { key: i }), headerGroup.headers.map(function (column, i) { return (_createElement("th", __assign({}, column.getHeaderProps(), { key: i }),
                        column.canGroupBy && withGroup ? (_jsx("span", __assign({}, column.getGroupByToggleProps(), { className: 'mr-1' }, { children: column.isGrouped ? _jsx(FaDotCircle, {}, void 0) : _jsx(FaUsers, {}, void 0) }), void 0)) : null,
                        column.render('Header'),
                        _jsx("span", __assign({}, column.getSortByToggleProps(), { className: 'mr-1' }, { children: column.isSorted && withSort
                                ? column.isSortedDesc
                                    ? _jsx(FaLongArrowAltDown, {}, void 0)
                                    : _jsx(FaLongArrowAltUp, {}, void 0)
                                : column.disableSortBy ? '' : _jsx(FaArrowsAltV, {}, void 0) }), void 0))); }))); }) }, void 0), _jsxs("tbody", __assign({}, getTableBodyProps(), { children: [loading && _.isEmpty(page) && _jsx("tr", { children: _jsx("td", { children: _jsx(Spinner, {}, void 0) }, void 0) }, void 0), !loading && !_.isEmpty(page) && page.map(function (row, i) {
                            prepareRow(row);
                            return (_createElement("tr", __assign({}, row.getRowProps(), { key: i, onClick: function () { return onSelectRow(row); }, style: __assign({}, _.get(row.original, 'id') == _.get(selectedRow, 'id') && handleSelectRow &&
                                    { backgroundColor: '#d1d3e2' }) }), row.cells.map(function (cell, i) { return _createElement("td", __assign({}, cell.getCellProps(), { key: i, className: 'align-middle' }), cell.isGrouped && withGroup ? (
                            // If it's a grouped cell, add an expander and row count
                            _jsxs(_Fragment, { children: [_jsx("span", __assign({}, row.getToggleRowExpandedProps(), { className: 'mr-1' }, { children: row.isExpanded ? _jsx(FaAngleDown, {}, void 0) : _jsx(FaAngleRight, {}, void 0) }), void 0), ' ', cell.render('Cell'), " (", row.subRows.length, ")"] }, void 0)) : cell.isAggregated ? (
                            // If the cell is aggregated, use the Aggregated
                            // renderer for cell
                            cell.render('Aggregated')) : cell.isPlaceholder ? null : ( // For cells with repeated values, render null
                            // Otherwise, just render the regular cell
                            cell.render('Cell'))); })));
                        }), !loading && _.isEmpty(page) && _jsx("tr", { children: _jsx("td", __assign({ colSpan: 5 }, { children: "\u041D\u0438\u0447\u0435\u0433\u043E \u043D\u0435 \u043D\u0430\u0439\u0434\u0435\u043D\u043E" }), void 0) }, void 0)] }), void 0)] }), void 0) }), void 0));
}
export default Table;
