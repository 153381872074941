import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//
//  Copyright (C) Quantron Systems LLC.
//  All Rights Reserved.
//
//  This file is part of the private project.
//  For conditions of distribution and use,
//  please contact sales@quantron-systems.com
//
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
import _ from 'lodash';
import { useEffect } from 'react';
import { FaSearch } from 'react-icons/fa';
import { setSearch } from '../../redux/slices';
import { useUrl } from '../../hooks';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { InputGroup, FormControl } from './bootstrap';
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function Search() {
    var dispatch = useAppDispatch();
    var search = useAppSelector(function (state) { return state.filters.search; });
    var _a = useUrl(), urlParams = _a.urlParams, setUrlParams = _a.setUrlParams;
    useEffect(function () {
        setUrlParams({
            search: search
        });
    }, [search]);
    useEffect(function () {
        var searchValue = urlParams === null || urlParams === void 0 ? void 0 : urlParams.search;
        if (searchValue != undefined && search != searchValue) {
            dispatch(setSearch(searchValue));
        }
        else {
            dispatch(setSearch(''));
        }
    }, []);
    var handleSearch = function (event) {
        var searchValue = event.currentTarget ? event.currentTarget.value : '';
        var setSearchDebounced = _.debounce(function () { return dispatch(setSearch(searchValue)); }, 500);
        setSearchDebounced();
    };
    return (_jsxs(InputGroup, { children: [_jsx(FormControl, { placeholder: '\u041F\u043E\u0438\u0441\u043A', defaultValue: search, onChange: handleSearch }, void 0), _jsx(InputGroup.Text, { children: _jsx(FaSearch, {}, void 0) }, void 0)] }, void 0));
}
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export default Search;
