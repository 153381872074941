import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import moment from 'moment';
import { ToastContainer } from 'react-toastify';
import { injectStyle } from 'react-toastify/dist/inject-style';
import Routes from './routes';
import './theme/sb-admin-2.scss';
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
if (typeof window !== 'undefined') {
    injectStyle();
}
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
moment.locale('ru', {
    months: [
        'Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август',
        'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'
    ],
    weekdaysMin: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
    weekdays: ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'],
    week: {
        dow: 1
    }
});
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function App() {
    return (_jsxs(_Fragment, { children: [_jsx(Routes, {}, void 0), _jsx(ToastContainer, {}, void 0)] }, void 0));
}
export default App;
