var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import _ from 'lodash';
import { useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { Button, Row, Col, Form, Modal } from '../../components/common/bootstrap';
import Spinner from '../../components/common/Spinner';
import { stationAPI } from '../../api/stationAPI';
import { FileInput, SelectInput } from '../../components/common/inputs';
import { categories, backgroundSides } from '../../helpers';
import { saveBackgroundSchema } from './validations';
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function BackgroundDownloadForm(_a) {
    var _this = this;
    var onHide = _a.onHide;
    var _b = useParams().id, id = _b === void 0 ? '' : _b;
    var _c = __read(stationAPI.useSaveBackgroundMutation(), 2), saveBackground = _c[0], isLoading = _c[1].isLoading;
    var _d = useForm({
        mode: 'onTouched',
        resolver: yupResolver(saveBackgroundSchema)
    }), register = _d.register, handleSubmit = _d.handleSubmit, getValues = _d.getValues, errors = _d.formState.errors;
    var fileName = _.get(getValues('file'), '0.name', '');
    var handleSaveBackground = function (_a) {
        var file = _a.file, side = _a.side, category = _a.category;
        return __awaiter(_this, void 0, void 0, function () {
            var formData;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        formData = new FormData();
                        if (!(file && side && category)) return [3 /*break*/, 2];
                        formData.append('file', file[0]);
                        formData.append('left', '1, 1');
                        formData.append('right', '1, 1');
                        formData.append('side', side);
                        formData.append('category', category);
                        return [4 /*yield*/, saveBackground({
                                stationId: id,
                                body: formData
                            })];
                    case 1:
                        _b.sent();
                        onHide();
                        _b.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    return (_jsxs(Modal, __assign({ show: true, onHide: onHide }, { children: [_jsx(Modal.Header, __assign({ closeButton: true }, { children: _jsx(Modal.Title, { children: "\u0417\u0430\u0433\u0440\u0443\u0437\u0438\u0442\u044C \u0444\u043E\u043D" }, void 0) }), void 0), _jsx(Modal.Body, { children: _jsx(Form, { children: _jsx(Row, { children: _jsx(Col, __assign({ md: 12 }, { children: _jsxs(Form.Group, { children: [_jsx(FileInput, { register: register, name: 'file', fileName: fileName, errors: errors, label: '\u0424\u043E\u0442\u043E' }, void 0), _jsx(SelectInput, { register: register, name: 'category', errors: errors, label: '\u041A\u0430\u0442\u0435\u0433\u043E\u0440\u0438\u044F', values: categories }, void 0), _jsx(SelectInput, { register: register, name: 'side', errors: errors, label: '\u0412\u0438\u0434', values: backgroundSides }, void 0)] }, void 0) }), void 0) }, void 0) }, void 0) }, void 0), _jsx(Modal.Footer, { children: _jsx("div", __assign({ className: 'text-right' }, { children: _jsx(Button, __assign({ variant: 'primary', type: 'submit', disabled: isLoading, onClick: handleSubmit(handleSaveBackground) }, { children: isLoading ? _jsx(Spinner, { sm: true }, void 0) : 'Сохранить' }), void 0) }), void 0) }, void 0)] }), void 0));
}
export default BackgroundDownloadForm;
