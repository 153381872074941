////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//
//  Copyright (C) Quantron Systems LLC.
//  All Rights Reserved.
//
//  This file is part of the private project.
//  For conditions of distribution and use,
//  please contact sales@quantron-systems.com
//
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// eslint-disable-next-line import/named
import { useDispatch, useSelector } from 'react-redux';
export var useAppDispatch = function () { return useDispatch(); };
export var useAppSelector = useSelector;
