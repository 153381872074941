////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//
//  Copyright (C) Quantron Systems LLC.
//  All Rights Reserved.
//
//  This file is part of the private project.
//  For conditions of distribution and use,
//  please contact sales@quantron-systems.com
//
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// eslint-disable-next-line import/named
import { toast } from 'react-toastify';
import moment from 'moment';
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export var dateFormat = 'DD.MM.YYYY';
export var serverDateTimeFormat = 'YYYY-MM-DDTHH:mm:ss.SSS';
export var serverTimeFormat = 'HH:mm:ss.SSS';
export var serverDateOnlyFormat = 'YYYY-MM-DD';
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export var monthRusMap = ['январь', 'февраль', 'март', 'апрель', 'май', 'июнь', 'июль', 'август', 'сентябрь',
    'октябрь', 'ноябрь', 'декабрь'];
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export var categories = [
    { id: 'A', name: 'A (Мотоциклы и мотороллеры)' },
    { id: 'B', name: 'B (Легковые автомобили)' },
    { id: 'C', name: 'C (Грузовые автомобили)' },
    { id: 'D', name: 'D (Автобусы)' },
    { id: 'E', name: 'E (Прицепы)' }
];
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export var maskTypeValues = [
    { id: 'side', name: 'Сбоку' },
    { id: 'rightAngle', name: 'Спереди' },
    { id: 'none', name: 'Без трафарета' }
];
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export var statusDictionaries = [
    { id: 'Enabled', name: 'Активен' },
    { id: 'Disabled', name: 'Не активен' }
];
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export var backgroundSides = [
    { id: 'front', name: 'Спереди' },
    { id: 'back', name: 'Сзади' }
];
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export var dateToISO = function (date) {
    return moment(date, dateFormat).format('YYYY-MM-DDT00:00:00+03:00');
};
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export var dateToISOEndTime = function (date) {
    return moment(date, dateFormat).format('YYYY-MM-DDT23:59:00+03:00');
};
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export var formatServerDateTime = function (date) {
    return moment(date, serverDateTimeFormat).format(dateFormat);
};
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export var notify = function (response, text) {
    var theme = {
        theme: 'colored'
    };
    if (response) {
        switch (response.status) {
            case 200:
                toast.success(text ? text : 'Выполнено успешно!', theme);
                break;
            case 401:
                toast.error(text ? text : 'Вы не авторизованы!', theme);
                break;
            case 400:
                toast.error(text ? text : 'Ошибка в введённых данных!', theme);
                break;
            case 404:
                toast.error(text ? text : 'Запрашиваемый адрес не найден!', theme);
                break;
            default:
                toast.error(text ? text : 'Ошибка сети!', theme);
                break;
        }
    }
};
