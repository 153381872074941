var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//
//  Copyright (C) Quantron Systems LLC.
//  All Rights Reserved.
//
//  This file is part of the private project.
//  For conditions of distribution and use,
//  please contact sales@quantron-systems.com
//
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
import { useState, useRef } from 'react';
import _ from 'lodash';
import { NavLink, Link } from 'react-router-dom';
import { FaUserFriends, FaCarAlt, FaClipboard, FaAngleRight, FaAngleLeft, FaAngleDown } from 'react-icons/fa';
import { useOutsideClicker, useAuth } from '../hooks';
import { useAppDispatch, useAppSelector } from '../hooks/redux';
import { setSidebarToggled } from '../redux/slices';
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function Sidebar() {
    var dispatch = useAppDispatch();
    var isToggled = useAppSelector(function (state) { return state.sidebar.isToggled; });
    var sidebarClassNames = "navbar-nav bg-gradient-primary sidebar sidebar-dark accordion ".concat(isToggled ? 'toggled' : '');
    var brandClassnames = 'sidebar-brand d-flex align-items-center justify-content-center';
    var isAdmin = useAuth().isAdmin;
    return (_jsxs("ul", __assign({ className: sidebarClassNames }, { children: [_jsx(NavLink, __assign({ to: '/', className: brandClassnames }, { children: _jsx("div", { children: "Photo stove admin" }, void 0) }), void 0), _jsx("hr", { className: 'sidebar-divider my-0' }, void 0), isAdmin && _jsx(Partners, { isToggled: isToggled }, void 0), _jsx(Stations, { isToggled: isToggled }, void 0), _jsx(Reports, { isToggled: isToggled }, void 0), _jsx("hr", { className: 'sidebar-divider d-none d-md-block' }, void 0), _jsx("div", __assign({ className: 'text-center d-none d-md-inline' }, { children: _jsx("button", __assign({ className: 'rounded-circle border-0', id: 'sidebarToggle', style: { color: 'rgba(255,255,255,0.5)' }, onClick: function () { return dispatch(setSidebarToggled(!isToggled)); } }, { children: isToggled
                        ? _jsx(FaAngleRight, {}, void 0)
                        : _jsx(FaAngleLeft, {}, void 0) }), void 0) }), void 0)] }), void 0));
}
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function Partners(_a) {
    var isToggled = _a.isToggled;
    var title = 'Партнёры';
    var links = [
        { title: 'Добавить партнёра', to: '/partners/new' },
        { title: 'Все партнёры', to: '/partners' }
    ];
    return (_jsx(SidebarNavItem, { title: title, links: links, icon: FaUserFriends, isToggled: isToggled }, void 0));
}
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function Stations(_a) {
    var isToggled = _a.isToggled;
    var title = 'Станции';
    var links = [
        { title: 'Создать станцию', to: '/stations/new' },
        { title: 'Все станции', to: '/stations' }
    ];
    return (_jsx(SidebarNavItem, { title: title, links: links, icon: FaCarAlt, isToggled: isToggled }, void 0));
}
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function Reports(_a) {
    var isToggled = _a.isToggled;
    var title = 'Отчёты';
    var links = [
        { title: 'Отчёты финансов', to: '/financesReport' }
    ];
    return (_jsx(SidebarNavItem, { title: title, links: links, icon: FaClipboard, isToggled: isToggled }, void 0));
}
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function SidebarNavItem(_a) {
    var isToggled = _a.isToggled, title = _a.title, links = _a.links, icon = _a.icon;
    var _b = __read(useState(false), 2), show = _b[0], setShow = _b[1];
    var collapsedContentRef = useRef(null);
    var NavItemIcon = icon;
    var toggleVisible = function () {
        setShow(!show);
    };
    if (collapsedContentRef) {
        useOutsideClicker(collapsedContentRef, setShow);
    }
    var angleClasses = 'float-right mt-1 d-angle-none';
    var navItemAngle = !show ? _jsx(FaAngleRight, { className: angleClasses }, void 0) : _jsx(FaAngleDown, { className: angleClasses }, void 0);
    return (_jsxs("li", __assign({ className: 'nav-item', ref: collapsedContentRef }, { children: [_jsxs("div", __assign({ className: 'nav-link', onClick: toggleVisible }, { children: [_jsx(NavItemIcon, { className: "".concat(!isToggled ? 'mr-2' : '') }, void 0), _jsx("span", { children: title }, void 0), !isToggled && navItemAngle] }), void 0), show && _jsx("div", __assign({ className: 'collapse show' }, { children: _jsxs("div", __assign({ className: 'bg-white py-2 collapse-inner rounded' }, { children: [_jsx("h6", __assign({ className: 'collapse-header' }, { children: title }), void 0), _.map(links, function (link) {
                            return _jsx(Link, __assign({ className: 'collapse-item', to: link.to, onClick: toggleVisible }, { children: link.title }), link.to);
                        })] }), void 0) }), void 0)] }), void 0));
}
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export default Sidebar;
