var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//
//  Copyright (C) Quantron Systems LLC.
//  All Rights Reserved.
//
//  This file is part of the private project.
//  For conditions of distribution and use,
//  please contact sales@quantron-systems.com
//
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
import { useState } from 'react';
import { Link, Outlet } from 'react-router-dom';
import Cookies from 'js-cookie';
import { config } from '@fortawesome/fontawesome-svg-core';
import { FaSignOutAlt, FaBars } from 'react-icons/fa';
import { useAuth } from '../hooks';
import { useAppDispatch, useAppSelector } from '../hooks/redux';
import { setSidebarToggled } from '../redux/slices';
import { clearStore } from '../redux/actions';
import Sidebar from './Sidebar';
import Footer from './Footer';
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function MainLayout() {
    var dispatch = useAppDispatch();
    var isToggled = useAppSelector(function (state) { return state.sidebar.isToggled; });
    var _a = __read(useState(false), 2), showDropdown = _a[0], setShowDropdown = _a[1];
    var name = useAuth().name;
    config.searchPseudoElements = true;
    var toggleDropdown = function () {
        setShowDropdown(!showDropdown);
    };
    var logout = function () {
        Cookies.remove('auth-key');
        Cookies.remove('username');
        dispatch(clearStore());
    };
    return (_jsxs("div", __assign({ id: 'wrapper' }, { children: [_jsx(Sidebar, {}, void 0), _jsxs("div", __assign({ id: 'content-wrapper', className: 'd-flex flex-column' }, { children: [_jsxs("div", __assign({ id: 'content' }, { children: [_jsxs("nav", __assign({ className: 'navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow' }, { children: [_jsx("button", __assign({ id: 'sidebarToggleTop', className: 'btn btn-link d-md-none rounded-circle mr-auto', onClick: function () { return dispatch(setSidebarToggled(!isToggled)); } }, { children: _jsx(FaBars, {}, void 0) }), void 0), _jsx("ul", __assign({ className: 'navbar-nav ml-auto' }, { children: _jsxs("li", __assign({ className: 'nav-item dropdown no-arrow' }, { children: [_jsx("div", __assign({ className: 'nav-link dropdown-toggle', id: 'userDropdown', role: 'button', "data-toggle": 'dropdown', "aria-haspopup": 'true', "aria-expanded": 'false', onClick: toggleDropdown }, { children: _jsx("span", __assign({ className: 'mr-2 d-lg-inline text-gray-600 small' }, { children: name }), void 0) }), void 0), showDropdown &&
                                                    _jsx("div", __assign({ className: 'dropdown-menu dropdown-menu-right shadow animated--grow-in show', onClick: logout }, { children: _jsxs(Link, __assign({ className: 'dropdown-item', to: '/login' }, { children: [_jsx(FaSignOutAlt, { className: 'mr-2' }, void 0), "\u0412\u044B\u0439\u0442\u0438"] }), void 0) }), void 0)] }), void 0) }), void 0)] }), void 0), _jsx("div", __assign({ className: 'container-fluid' }, { children: _jsx(Outlet, {}, void 0) }), void 0)] }), void 0), _jsx(Footer, {}, void 0)] }), void 0)] }), void 0));
}
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export default MainLayout;
