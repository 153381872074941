var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import _ from 'lodash';
import { FaCircle } from 'react-icons/fa';
import { Tooltip, OverlayTrigger } from '../common/bootstrap';
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
var renderTooltip = function (status, dictionaries) {
    var tooltip = '';
    var dictionary = _.find(dictionaries, function (item) { return item.id == status; });
    if (dictionary) {
        tooltip = dictionary.name;
    }
    return _jsx(Tooltip, __assign({ id: status }, { children: tooltip }), void 0);
};
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function StatusControl(_a) {
    var status = _a.status, dictionaries = _a.dictionaries;
    var color = 'black';
    switch (status) {
        case 'Enabled':
            color = 'green';
            break;
        case 'Disabled':
            color = 'red';
            break;
        default:
            console.warn('Unknown status: ' + status);
            break;
    }
    return (_jsx("div", __assign({ className: 'd-flex justify-content-center' }, { children: _jsx(OverlayTrigger, __assign({ placement: 'left', overlay: renderTooltip(status, dictionaries) }, { children: _jsx("div", { children: _jsx(FaCircle, { color: color }, void 0) }, void 0) }), void 0) }), void 0));
}
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export default StatusControl;
