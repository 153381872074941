////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//
//  Copyright (C) Quantron Systems LLC.
//  All Rights Reserved.
//
//  This file is part of the private project.
//  For conditions of distribution and use,
//  please contact sales@quantron-systems.com
//
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import _ from 'lodash';
import { useLocation } from 'react-router';
import { useSearchParams } from 'react-router-dom';
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function useUrl() {
    var location = useLocation();
    var _a = __read(useSearchParams(), 2), searchParams = _a[0], setSearchParams = _a[1];
    var hash = location.hash, pathname = location.pathname, search = location.search, state = location.state, key = location.key;
    var urlParams = Object.fromEntries(__spreadArray([], __read(searchParams), false));
    var setUrlParams = function (object) {
        setSearchParams(__assign(__assign({}, urlParams), object));
    };
    var removeUrlParams = function (fields) {
        setSearchParams(_.omit(urlParams, fields));
    };
    return {
        urlParams: urlParams,
        location: location,
        hash: hash,
        pathname: pathname,
        search: search,
        state: state,
        key: key,
        setUrlParams: setUrlParams,
        removeUrlParams: removeUrlParams
    };
}
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export default useUrl;
