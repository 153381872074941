////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//
//  Copyright (C) Quantron Systems LLC.
//  All Rights Reserved.
//
//  This file is part of the private project.
//  For conditions of distribution and use,
//  please contact sales@quantron-systems.com
//
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
import Cookies from 'js-cookie';
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export default function useAuth() {
    var name = Cookies.get('username');
    var userId = Cookies.get('userId');
    var authKey = Cookies.get('auth-key');
    var isAdmin = Cookies.get('role') === 'Admin';
    return {
        isAuth: !!name,
        name: name,
        userId: userId,
        authKey: authKey,
        isAdmin: isAdmin
    };
}
