var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { createElement as _createElement } from "react";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//
//  Copyright (C) Quantron Systems LLC.
//  All Rights Reserved.
//
//  This file is part of the private project.
//  For conditions of distribution and use,
//  please contact sales@quantron-systems.com
//
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
import { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import InputMask from 'react-input-mask';
import Datetime from 'react-datetime';
import { FaCalendar } from 'react-icons/fa';
import moment from 'moment';
import { monthRusMap } from '../../helpers';
import { useOutsideClicker } from '../../hooks';
import { useAppDispatch } from '../../hooks/redux';
import { Form, InputGroup, Button } from './bootstrap';
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export var TextInput = function (_a) {
    var register = _a.register, name = _a.name, errors = _a.errors, _b = _a.mb, mb = _b === void 0 ? 2 : _b, label = _a.label;
    var error = _.get(errors, name);
    return (_jsxs(Form.Group, __assign({ className: "mb-".concat(mb) }, { children: [label && _jsx(Form.Label, __assign({ className: 'font-weight-bold' }, { children: label }), void 0), _jsx(Form.Control, __assign({}, register(name), { className: "".concat(error ? 'is-invalid' : '') }), void 0), (error === null || error === void 0 ? void 0 : error.message) && _jsx(Form.Control.Feedback, __assign({ type: 'invalid' }, { children: error.message }), void 0)] }), void 0));
};
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export var FileInput = function (_a) {
    var register = _a.register, name = _a.name, errors = _a.errors, _b = _a.mb, mb = _b === void 0 ? 2 : _b, label = _a.label, fileName = _a.fileName;
    var error = _.get(errors, name);
    return (_jsxs(Form.Group, __assign({ className: "mb-".concat(mb) }, { children: [label && _jsx(Form.Label, __assign({ className: 'font-weight-bold' }, { children: label }), void 0), _jsxs("div", __assign({ className: 'position-relative' }, { children: [_jsx(Form.Label, __assign({ className: "".concat(error ? 'is-invalid' : '', " custom-file-label form-control") }, { children: fileName }), void 0), _jsx(Form.Control, __assign({}, register(name), { className: 'custom-file-input', type: 'file' }), void 0)] }), void 0), (error === null || error === void 0 ? void 0 : error.message) && _jsx(Form.Control.Feedback, __assign({ type: 'invalid' }, { children: error.message }), void 0)] }), void 0));
};
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export var NumberInput = function (_a) {
    var register = _a.register, name = _a.name, errors = _a.errors, _b = _a.mb, mb = _b === void 0 ? 2 : _b, label = _a.label;
    var error = _.get(errors, name);
    return (_jsxs(Form.Group, __assign({ className: "mb-".concat(mb) }, { children: [label && _jsx(Form.Label, __assign({ className: 'font-weight-bold' }, { children: label }), void 0), _jsx(Form.Control, __assign({}, register(name), { type: 'number', className: "".concat(error ? 'is-invalid' : '') }), void 0), (error === null || error === void 0 ? void 0 : error.message) && _jsx(Form.Control.Feedback, __assign({ type: 'invalid' }, { children: error.message }), void 0)] }), void 0));
};
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export var SelectInput = function (_a) {
    var register = _a.register, name = _a.name, errors = _a.errors, _b = _a.mb, mb = _b === void 0 ? 2 : _b, label = _a.label, disabled = _a.disabled, values = _a.values, _c = _a.emptyValue, emptyValue = _c === void 0 ? '- - -' : _c, _d = _a.optionName, optionName = _d === void 0 ? 'name' : _d, defaultValue = _a.defaultValue;
    var error = _.get(errors, name);
    return (_jsxs(Form.Group, __assign({ className: "mb-".concat(mb) }, { children: [label && _jsx(Form.Label, __assign({ className: 'font-weight-bold' }, { children: label }), void 0), _jsxs(Form.Select, __assign({}, register(name), { className: "custom-select ".concat(error ? 'is-invalid' : ''), disabled: disabled, defaultValue: defaultValue }, { children: [_jsx("option", __assign({ value: '' }, { children: emptyValue }), void 0), _.map(values, function (value, index) {
                        return _jsx("option", __assign({ value: value.id || value }, { children: _.get(value, optionName) }), value.id || index);
                    })] }), void 0), (error === null || error === void 0 ? void 0 : error.message) && _jsx(Form.Control.Feedback, __assign({ type: 'invalid' }, { children: error.message }), void 0)] }), void 0));
};
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export var DictionarySelectInput = function (_a) {
    var _b = _a.mb, mb = _b === void 0 ? 2 : _b, label = _a.label, _c = _a.optionName, optionName = _c === void 0 ? 'name' : _c, values = _a.values, emptyValue = _a.emptyValue, action = _a.action, defaultValue = _a.defaultValue, disabled = _a.disabled;
    var dispatch = useAppDispatch();
    var handleChange = function (event) {
        var selectedValue = event.target.value;
        dispatch(action(selectedValue));
    };
    return (_jsxs(Form.Group, __assign({ className: "mb-".concat(mb) }, { children: [label && _jsx(Form.Label, __assign({ className: 'font-weight-bold' }, { children: label }), void 0), _jsxs(Form.Select, __assign({ className: 'custom-select', onChange: handleChange, value: defaultValue, disabled: disabled }, { children: [emptyValue && _jsx("option", __assign({ value: '' }, { children: emptyValue }), void 0), _.map(values, function (value) {
                        return _jsx("option", __assign({ value: value.id }, { children: value[optionName] }), value.id);
                    })] }), void 0)] }), void 0));
};
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export var DateInput = function (_a) {
    var _b = _a.label, label = _b === void 0 ? '' : _b, _c = _a.mb, mb = _c === void 0 ? 0 : _c, action = _a.action, _d = _a.defaultValue, defaultValue = _d === void 0 ? '' : _d;
    var _e = __read(useState(''), 2), value = _e[0], setValue = _e[1];
    var _f = __read(useState(false), 2), showCalendar = _f[0], setShowCalendar = _f[1];
    var dateCalendarRef = useRef(null);
    var dispatch = useAppDispatch();
    var setDateFormat = function (text, inputFormat, outputFormat) {
        if (inputFormat === void 0) { inputFormat = 'DDMMYYYY'; }
        if (outputFormat === void 0) { outputFormat = 'DD.MM.YYYY'; }
        var value = (text + '').replace(/[^\d]/g, '');
        if (value) {
            var time = moment(value, inputFormat);
            if (time.isValid()) {
                value = time.format(outputFormat);
            }
            else {
                value = '';
            }
        }
        else {
            value = '';
        }
        return value;
    };
    useEffect(function () {
        if (defaultValue)
            setValue(defaultValue);
    }, [defaultValue]);
    var handleChange = function (event) {
        var date = event.target.value;
        setValue(date);
    };
    var onBlur = function (value) {
        var formatted = setDateFormat(value);
        setValue(formatted);
        dispatch(action(formatted));
    };
    var handleSelectedDay = function (value) {
        var date = moment(value).format('DD.MM.YYYY');
        setValue(setDateFormat(date));
        dispatch(action(date));
        setShowCalendar(false);
    };
    var inputProperties = {
        className: 'pl-0.375',
        value: value,
        mask: '99.99.9999',
        alwaysShowMask: true,
        onChange: handleChange,
        onBlur: function () { return onBlur(value); }
    };
    var calendarProperties = {
        className: 'datetime date-start',
        bsSize: 'small',
        value: value,
        onChange: function (value) { return handleSelectedDay(value); },
        closeOnSelect: true,
        disableOnClickOutside: true,
        dateFormat: 'DD.MM.YYYY',
        timeFormat: false,
        input: false,
        locale: 'ru',
        renderMonth: function (props, month) {
            return _createElement("td", __assign({}, props, { className: 'p-2', key: month }), _.upperFirst(monthRusMap[month]));
        }
    };
    if (dateCalendarRef) {
        useOutsideClicker(dateCalendarRef, setShowCalendar);
    }
    return (_jsxs("div", { children: [label && _jsx(Form.Label, __assign({ className: 'mr-2 font-weight-bold' }, { children: label }), void 0), _jsx(InputMask, __assign({}, inputProperties, { children: function (inputProps) { return _jsxs(InputGroup, { children: [_jsx(Form.Control, __assign({}, inputProps, { type: 'text', className: "mb-".concat(mb) }), void 0), _jsx(Button, __assign({ variant: 'primary', onClick: function () { return setShowCalendar(!showCalendar); } }, { children: _jsx(FaCalendar, {}, void 0) }), void 0)] }, void 0); } }), void 0), showCalendar && _jsx("div", __assign({ ref: dateCalendarRef }, { children: _jsx(Datetime, __assign({}, calendarProperties), void 0) }), void 0)] }, void 0));
};
