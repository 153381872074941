var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//
//  Copyright (C) Quantron Systems LLC.
//  All Rights Reserved.
//
//  This file is part of the private project.
//  For conditions of distribution and use,
//  please contact sales@quantron-systems.com
//
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useNavigate, Link } from 'react-router-dom';
import withConfirmation from '../../hoc/withConfirmation';
import Table from '../../components/Table';
import { partnerAPI } from '../../api/partnerAPI';
import { Row, Col } from '../../components/common/bootstrap';
import { maskTypeValues, statusDictionaries } from '../../helpers';
import { Toolbar, StatusControl, CheckControl, CheckAdmin, Search } from '../../components/common';
import { useAppSelector } from '../../hooks/redux';
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function Partners() {
    var _a = partnerAPI.useGetOwnerListQuery(''), _b = _a.data, data = _b === void 0 ? [] : _b, isLoading = _a.isLoading;
    var _c = __read(useState(null), 2), selectedRow = _c[0], setSelectedRow = _c[1];
    var _d = __read(useState([]), 2), filteredData = _d[0], setFilteredData = _d[1];
    var search = useAppSelector(function (state) { return state.filters.search; });
    var navigate = useNavigate();
    var handleEdit = function (original) {
        if (original.id) {
            navigate("/partners/".concat(original.id));
        }
    };
    var onFilterData = function () {
        var filtered = _.filter(data, function (item) {
            return _.includes(_.toLower(_.get(item, 'owner')), _.toLower(search));
        });
        setFilteredData(filtered);
    };
    useEffect(function () {
        if (!_.isEmpty(data)) {
            onFilterData();
        }
    }, [data]);
    useEffect(function () {
        if (search != undefined && !_.isEmpty(data)) {
            onFilterData();
        }
    }, [search]);
    var columns = React.useMemo(function () { return [
        {
            Header: 'Все партнёры',
            disableSortBy: true,
            columns: [
                {
                    Header: 'Партнёр',
                    accessor: 'owner',
                    Cell: function (_a) {
                        var row = _a.row;
                        var original = row.original;
                        return _jsx(Link, __assign({ to: "/partners/".concat(original.id) }, { children: original.owner }), void 0);
                    }
                },
                {
                    Header: 'Идентификатор',
                    accessor: 'id',
                    disableSortBy: true
                },
                {
                    Header: 'Ключ',
                    accessor: 'key',
                    disableSortBy: true
                },
                {
                    Header: 'Тип трафарета',
                    accessor: 'maskType',
                    disableSortBy: true,
                    Cell: function (_a) {
                        var row = _a.row;
                        var maskType = _.find(maskTypeValues, function (item) {
                            return item.id == _.toLower(row.original.maskType);
                        });
                        if (maskType)
                            return maskType.name;
                        return '';
                    }
                },
                {
                    Header: 'Статус',
                    accessor: 'enabled',
                    disableSortBy: true,
                    Cell: function (_a) {
                        var row = _a.row;
                        var status = row.original.enabled
                            ? _jsx(StatusControl, { status: 'Enabled', dictionaries: statusDictionaries }, void 0)
                            : _jsx(StatusControl, { status: 'Disabled', dictionaries: statusDictionaries }, void 0);
                        return status;
                    }
                },
                {
                    Header: 'Админ',
                    accessor: 'isAdmin',
                    disableSortBy: true,
                    Cell: function (_a) {
                        var row = _a.row;
                        return _jsx(CheckControl, { value: row.original.isAdmin }, void 0);
                    }
                },
                {
                    Header: 'Количество станций',
                    accessor: 'stationsCount'
                }
            ]
        }
    ]; }, []);
    return (_jsxs(CheckAdmin, { children: [_jsxs(Row, { children: [_jsx(Col, __assign({ className: 'mb-3', md: 2 }, { children: _jsx(Toolbar, { buttons: [
                                {
                                    icon: 'add',
                                    onClick: function () { return navigate('/partners/new'); },
                                    disabled: false
                                },
                                {
                                    icon: 'edit',
                                    onClick: function () { return handleEdit(selectedRow); },
                                    disabled: !selectedRow
                                }
                            ] }, void 0) }), void 0), _jsx(Col, __assign({ md: 2, className: 'mb-3' }, { children: _jsx(Search, {}, void 0) }), void 0)] }, void 0), _jsx(Row, { children: _jsx(Col, { children: _jsx(Table, { columns: columns, data: filteredData, loading: isLoading, pageCount: 1000, handleSelectRow: setSelectedRow }, void 0) }, void 0) }, void 0)] }, void 0));
}
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export default withConfirmation(Partners);
