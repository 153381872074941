var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import config from '../api/config';
import { Row, Col } from './common/bootstrap';
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function Footer() {
    return _jsx("footer", __assign({ className: 'container-fluid p-4 bg-white' }, { children: _jsx(Row, { children: _jsxs(Col, __assign({ xs: 12, className: 'd-flex justify-content-center small' }, { children: [_jsx("span", __assign({ className: 'mr-2' }, { children: "All rights reserved, 2022" }), void 0), _jsxs("span", { children: ["Version ", config.version] }, void 0)] }), void 0) }, void 0) }), void 0);
}
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export default Footer;
