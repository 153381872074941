var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { HashRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import config from '../config.json';
import { setupStore } from './redux';
import App from './App';
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
var store = setupStore();
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
if (config.SENTRY_ENABLED) {
    Sentry.init({
        dsn: config.SENTRY_DSN,
        integrations: [new BrowserTracing()],
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0
    });
}
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
ReactDOM.render(_jsx(Provider, __assign({ store: store }, { children: _jsx(Router, { children: _jsx(App, {}, void 0) }, void 0) }), void 0), document.getElementById('root'));
