var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import _ from 'lodash';
import { FaPlus, FaEdit, FaTrash, FaFileExport, FaSyncAlt } from 'react-icons/fa';
import { ButtonToolbar, ButtonGroup, Button } from './bootstrap';
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
var icons = {
    add: _jsx(FaPlus, {}, void 0),
    edit: _jsx(FaEdit, {}, void 0),
    delete: _jsx(FaTrash, {}, void 0),
    export: _jsx(FaFileExport, {}, void 0),
    refresh: _jsx(FaSyncAlt, {}, void 0)
};
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
var variant = {
    add: 'primary',
    edit: 'info',
    delete: 'danger',
    export: 'info',
    refresh: 'info'
};
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function Toolbar(_a) {
    var buttons = _a.buttons;
    return (_jsx(ButtonToolbar, __assign({ className: 'h-100' }, { children: _jsx(ButtonGroup, __assign({ className: 'me-2' }, { children: _.map(buttons, function (button) {
                return _jsx(Button, __assign({ variant: _.get(variant, button.icon), onClick: button.onClick, className: 'd-flex align-items-center', disabled: button.disabled }, { children: _.get(icons, button.icon) }), button.icon);
            }) }), void 0) }), void 0));
}
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export default Toolbar;
