////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//
//  Copyright (C) Quantron Systems LLC.
//  All Rights Reserved.
//
//  This file is part of the private project.
//  For conditions of distribution and use,
//  please contact sales@quantron-systems.com
//
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
import { CLEAR_STORE } from './constants';
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export var clearStore = function () { return ({ type: CLEAR_STORE }); };
