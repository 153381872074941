var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//
//  Copyright (C) Quantron Systems LLC.
//  All Rights Reserved.
//
//  This file is part of the private project.
//  For conditions of distribution and use,
//  please contact sales@quantron-systems.com
//
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
import React, { useEffect } from 'react';
import _ from 'lodash';
import { Panel, Row, Col } from '../../components/common/bootstrap';
import { reportsAPI } from '../../api/reportsAPI';
import { partnerAPI } from '../../api/partnerAPI';
import Table from '../../components/Table';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import StartEndFilter from '../../components/common/StartEndFilter';
import { DictionarySelectInput } from '../../components/common/inputs';
import { dateToISO, dateToISOEndTime, formatServerDateTime } from '../../helpers';
import { setOwnerId, setRequestType } from '../../redux/slices';
import { useAuth, useUrl } from '../../hooks';
import Chart from '../../components/common/Chart';
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function FinancesReports() {
    var _a = __read(reportsAPI.useFinancesReportMutation(), 2), getReport = _a[0], _b = _a[1], data = _b.data, isLoading = _b.isLoading;
    var ownerList = partnerAPI.useGetOwnerListQuery('');
    var _c = useAppSelector(function (state) { return state.filters; }), fromDate = _c.fromDate, toDate = _c.toDate, ownerId = _c.ownerId, requestType = _c.requestType;
    var values = requestType
        ? _.get(data, requestType, [])
        : _.get(data, 'values', []);
    var sortedValues = _.sortBy(values, 'dateTime');
    var selectedOwner = _.find(ownerList.data, function (item) { return item.id == ownerId; });
    var chartTitle = (selectedOwner === null || selectedOwner === void 0 ? void 0 : selectedOwner.owner) || 'Все партнёры';
    var onFormatDateTime = function (objects) {
        return _.map(objects, function (item) { return (__assign(__assign({}, item), { dateTime: formatServerDateTime(item.dateTime) })); });
    };
    useEffect(function () {
        if (fromDate && toDate) {
            getReport({
                from: dateToISO(fromDate),
                to: dateToISOEndTime(toDate),
                authKeyId: ownerId
            });
        }
    }, [fromDate, toDate, ownerId]);
    var getChartData = function (array) {
        var dateGroup = _.groupBy(onFormatDateTime(array), 'dateTime');
        var ownerGroup = _.groupBy(array, 'authKeyOwnerName');
        var labels = [];
        var data = [];
        var multiBarData = [];
        if (!selectedOwner) {
            _.forIn(ownerGroup, function (value, key) {
                multiBarData.push({ label: key, data: [] });
            });
        }
        _.forIn(dateGroup, function (value, key) {
            labels.push(key);
            data.push(value.length);
            if (!selectedOwner) {
                var dateOwners_1 = _.groupBy(value, 'authKeyOwnerName');
                _.forEach(multiBarData, function (item) {
                    var dateOwnerData = dateOwners_1[item.label];
                    item.data = __spreadArray(__spreadArray([], __read(item.data), false), [dateOwnerData ? dateOwnerData.length : 0], false);
                });
            }
        });
        if (!selectedOwner) {
            return ({ labels: labels, data: multiBarData });
        }
        return ({ labels: labels, data: data });
    };
    var columns = React.useMemo(function () { return [
        {
            Header: 'Отчёты финансов',
            disableSortBy: true,
            columns: [
                {
                    Header: 'Партнёр',
                    accessor: 'authKeyOwnerName'
                },
                {
                    Header: 'Дата',
                    accessor: 'dateTime'
                },
                {
                    Header: 'ID заявки',
                    accessor: 'requestId',
                    disableSortBy: true,
                    disableGroupBy: true
                }
            ]
        }
    ]; }, []);
    var chartData = getChartData(sortedValues);
    var groupByFields = ['authKeyOwnerName'];
    return (_jsxs(Row, { children: [_jsx(Col, __assign({ md: 12 }, { children: _jsx(Panel, { children: _jsxs(Row, { children: [_jsx(Col, __assign({ md: 3 }, { children: _jsx(StartEndFilter, {}, void 0) }), void 0), _jsx(Col, __assign({ md: 3 }, { children: _jsx(OwnerListFilter, { data: ownerList.data || [] }, void 0) }), void 0), _jsx(Col, __assign({ md: 3 }, { children: _jsx(RequestTypeFilter, {}, void 0) }), void 0)] }, void 0) }, void 0) }), void 0), _jsx(Col, __assign({ md: 6 }, { children: _jsx(Table, { data: onFormatDateTime(sortedValues), columns: columns, pageCount: 100, loading: isLoading, withGroup: true, groupByFields: groupByFields }, void 0) }), void 0), _jsx(Col, __assign({ md: 6 }, { children: _jsxs(Panel, __assign({ header: '\u0413\u0440\u0430\u0444\u0438\u043A \u043F\u043E \u0434\u043D\u044F\u043C' }, { children: [_jsx(Row, { children: _jsx(Col, __assign({ md: 12, className: 'mb-4' }, { children: _jsx(Chart, { labels: chartData === null || chartData === void 0 ? void 0 : chartData.labels, data: chartData === null || chartData === void 0 ? void 0 : chartData.data, datasetLabel: chartTitle, multiBar: !selectedOwner }, void 0) }), void 0) }, void 0), _jsx(Row, { children: _jsx(Col, __assign({ md: 12 }, { children: _jsxs("div", __assign({ className: 'd-flex justify-content-end' }, { children: ["\u041A\u043E\u043B\u0438\u0447\u0435\u0441\u0442\u0432\u043E \u0437\u0430\u044F\u0432\u043E\u043A \u0437\u0430 \u0432\u044B\u0431\u0440\u0430\u043D\u043D\u044B\u0439 \u043F\u0435\u0440\u0438\u043E\u0434:", _jsx("span", __assign({ className: 'font-weight-bold ml-2' }, { children: values.length }), void 0)] }), void 0) }), void 0) }, void 0)] }), void 0) }), void 0)] }, void 0));
}
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
var OwnerListFilter = function (_a) {
    var data = _a.data;
    var dispatch = useAppDispatch();
    var _b = useAuth(), isAdmin = _b.isAdmin, userId = _b.userId;
    var _c = useUrl(), urlParams = _c.urlParams, setUrlParams = _c.setUrlParams;
    var ownerId = useAppSelector(function (state) { return state.filters.ownerId; });
    useEffect(function () {
        setUrlParams({
            ownerId: ownerId
        });
    }, [ownerId]);
    useEffect(function () {
        var id = urlParams === null || urlParams === void 0 ? void 0 : urlParams.ownerId;
        if (id && ownerId != id) {
            dispatch(setOwnerId(id));
        }
    }, []);
    useEffect(function () {
        if (!isAdmin && ownerId != userId) {
            dispatch(setOwnerId(userId));
        }
    }, [data]);
    return (_jsx(DictionarySelectInput, { name: 'ownerId', action: setOwnerId, disabled: !isAdmin, defaultValue: ownerId, emptyValue: '\u0412\u0441\u0435 \u043F\u0430\u0440\u0442\u043D\u0451\u0440\u044B', optionName: 'owner', values: data }, void 0));
};
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
var RequestTypeFilter = function () {
    var dispatch = useAppDispatch();
    var isAdmin = useAuth().isAdmin;
    var _a = useUrl(), urlParams = _a.urlParams, setUrlParams = _a.setUrlParams;
    var requestType = useAppSelector(function (state) { return state.filters.requestType; });
    useEffect(function () {
        setUrlParams({
            requestType: requestType
        });
    }, [requestType]);
    useEffect(function () {
        var requestTypeUrl = urlParams === null || urlParams === void 0 ? void 0 : urlParams.requestType;
        if (requestTypeUrl && requestType != requestTypeUrl) {
            dispatch(setRequestType(requestTypeUrl));
        }
        else {
            dispatch(setRequestType('backgroundApply'));
        }
    }, []);
    return (_jsx(DictionarySelectInput, { name: 'requestType', action: setRequestType, disabled: !isAdmin, defaultValue: requestType, values: [
            { id: 'backgroundApply', name: 'Фоны' },
            { id: 'foundCardApply', name: 'Поиски карт' }
        ] }, void 0));
};
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export default FinancesReports;
