////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//
//  Copyright (C) Quantron Systems LLC.
//  All Rights Reserved.
//
//  This file is part of the private project.
//  For conditions of distribution and use,
//  please contact sales@quantron-systems.com
//
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
import * as Yup from 'yup';
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export var savePartnerSchema = Yup.object().shape({
    owner: Yup.string().required('Наименование партнера обязательно'),
    key: Yup.string().required('Ключ обязателен'),
    maskType: Yup.string().required('Вид трафарета обязателен'),
    isAdmin: Yup.bool(),
    enabled: Yup.bool()
});
