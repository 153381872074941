import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../hooks';
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
var GuestRoute = function (_a) {
    var children = _a.children;
    var location = useLocation();
    var url = new URLSearchParams(location.search.slice(1));
    var isAuth = useAuth().isAuth;
    return (_jsx(_Fragment, { children: isAuth ? _jsx(Navigate, { to: url.get('redirect') || '/' }, void 0) : children }, void 0));
};
export default GuestRoute;
