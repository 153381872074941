////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//
//  Copyright (C) Quantron Systems LLC.
//  All Rights Reserved.
//
//  This file is part of the private project.
//  For conditions of distribution and use,
//  please contact sales@quantron-systems.com
//
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/* eslint-disable no-undef */

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

module.exports = {
    authKeyName: process.env.HEADER_AUTH_KEY,
    apiUrl: process.env.API_URL,
    photoAppUrl: process.env.PHOTO_APP_URL,
    version: process.env.VERSION
};
